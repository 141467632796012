<template>
  <!-- 充值管理 -->
  <div class="topUpManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <router-link class="topUpButton" :to="{ path:'/accountManagementSystem/addTopUp' }">
          <el-button size="small" type="success">
            充值
          </el-button>
        </router-link>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 充值管理详情弹出窗 -->
    <el-dialog title="详情" :visible.sync="dialogVisible" width="42%" :close-on-click-modal="false">
      <DetailsPage details-title="" :detail-obj="topUpDetails" :details-item-arr="detailsItemArr" :column="2" border title="列表" />
    </el-dialog>
    <!-- 审核弹窗 -->
    <Audit ref="auditDialog" :audit-option-item="auditOptionItem" @reviewSubmitted="reviewSubmitted" />
  </div>
</template>

<script>
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import DetailsPage from '@/components/DetailsPage'
import Audit from '../../components/Audit'
import { timestampToTime } from '../../utils/util'
import { inoutLogPage, inoutLogPayAudit } from '../../api/accountManagementSystem'
export default {
  components: { Audit, FormSearch, Table, Pagination, DetailsPage },
  data() {
    return {
      dialogVisible: false,
      topUpDetails: {},
      auditId: '',
      auditOptionItem: [{ label: '通过', value: '01' }, { label: '拒绝', value: '20' }],
      itemData: [
        { label: '会员编码', prop: 'cmpId', width: 180 },
        { label: '会员名称', prop: 'cmpName', width: 180 },
        { label: '结算银行 ', prop: 'settleBankName', width: 180 },
        { label: '金额', prop: 'amount', width: 120, type: 'money' },
        { label: '支付方式', prop: 'payWay', width: 120, child: [{ value: '011', label: '现金' }, { value: '012', label: '网银转账' }, { value: '021', label: '银行接口' }, { value: '022', label: '柜台' }] },
        { label: '汇款银行 ', prop: 'openBankName', width: 120 },
        { label: '汇款账号 ', prop: 'openAccNo', width: 120 },
        { label: '状态 ', prop: 'state', width: 120, child: [{ value: '01', label: '有效' }, { value: '02', label: '待平台审核' }, { value: '10', label: '待银行审核' }, { value: '11', label: '银行拒绝' }, { value: '20', label: '平台拒绝' }] }
      ],
      operationButton: [
        { num: '02', val: 'state', bType: 'primary', label: '审核', handleEvent: this.auditRow },
        { bType: 'primary', label: '详情', handleEvent: this.productionSeeDetails }
      ],
      formItemArr: [
        { type: 'select', label: '结算银行', value: 'settleBankId', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('settleBankName') },
        { type: 'input', label: '会员名称', value: 'cmpName' }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        inorout: 1
      },
      detailsItemArr: [
        { label: '会员名称', value: 'cmpName' },
        { label: '结算银行', value: 'settleBankName' },
        { label: '充值金额(元)', money: 'amount' },
        { label: '渠道', value: 'operPlat', child: [{ id: '01', val: '平台' }, { id: '02', val: '银行' }] },
        { label: '支付方式', value: 'payWay', child: [{ id: '011', val: '现金' }, { id: '012', val: '网银转账' }, { id: '021', val: '银行接口' }, { id: '022', val: '柜台' }] },
        { label: '银行交易流水', value: 'bankBusiNo' },
        { label: '汇款银行', value: 'openBankName' },
        { label: '汇款账号名称', value: 'openAccName' },
        { label: '汇款账号', value: 'openAccNo' },
        { label: '汇款网点', value: 'openBankNet' },
        { label: '经办人', value: 'operMan' },
        { label: '充值时间', value: 'payTime' },
        { label: '状态', value: 'state', child: [{ id: '01', val: '有效' }, { id: '02', val: '待平台审核' }, { id: '10', val: '待银行审核' }, { id: '11', val: '银行拒绝' }, { id: '20', val: '平台拒绝' }] },
        { label: '备注', value: 'remark' }
      ]
    }
  },
  methods: {
    // 点击审核按钮
    reviewSubmitted(value) {
      value.id = this.auditId
      inoutLogPayAudit(value, res => {
        this.$message.success(res.msg)
        this.$refs.auditDialog.dialogAuditFormVisible = false
        this.getdata(true)
      })
    },
    auditRow(row) {
      this.auditId = row.id
      this.$refs.auditDialog.dialogAuditFormVisible = true
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          inorout: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      inoutLogPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 查看资金明细
    productionSeeDetails(row) {
      row.payTime = timestampToTime(row.payTime)
      this.topUpDetails = { ...row }
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.topUpManagementPageBox {
  width: 100%;
  .topUpButton{
    float: right;
    height: 42px;
  }
  .goBackButton{
    margin: 24px 48%;
  }
  .el-descriptions{
    padding: 0 18px;
  }
}
</style>
